import React from 'react';

import Button from 'aphrodite-shared/ui/Button/Button';

import styles from './FooterContact.module.scss';

import FacebookIcon from '../svg/FacebookIcon';
import LinkedInIcon from '../svg/LinkedInIcon';
import InstagramIcon from '../svg/InstagramIcon';
import TelIconSVG from '../../../SVGComponents/TelIconSVG';
import EmailIcon from '../svg/EmailIcon';
import { useIntl } from 'react-intl';
import messages from '../Footer.messages';
import { formatPhoneNumber } from '../../../util/localization';
import { fetchIsLoggedIn } from '../../../util/authHelpers';
import { Amplitude, AmplitudeWebsite } from '@iwoca/frontend-tracking-library';
import { useProductName } from '../../../components/IwModals/StepZeroModal/StepZeroModalHelpers';

const trackFooterCta = (ctaText, productName) => {
    const footerContactEvent = AmplitudeWebsite.getStep0ModalEventWithProductTypeAndText(
        AmplitudeWebsite.OPEN_FOOTER_STEP_0_MODAL,
        ctaText,
        productName
    );
    Amplitude.track(footerContactEvent);
};

const FooterContact = ({ ctaButton, email, hideCta }) => {
    const productName = useProductName();
    const { formatMessage } = useIntl();

    const loggedIn = fetchIsLoggedIn();

    const dataGaId = ctaButton
        ? ctaButton.dataGaId
        : 'footer__product_comparison_modal';
    const ctaButtonProps = {
        colour: 'primary',
        dataGaId
    };

    const ctaButtonText =
        (ctaButton && ctaButton.text) ||
        formatMessage({
            ...messages.apply
        });

    return (
        <section className={styles.background}>
            <div className={styles.FooterContact}>
                <div className={styles.social}>
                    <FacebookIcon />
                    <LinkedInIcon />
                    <InstagramIcon />
                </div>

                <div className={styles.tel}>
                    <TelIconSVG />
                    <a
                        href={formatPhoneNumber(
                            formatMessage({
                                ...messages.number
                            })
                        )}
                        data-ga-id="footer__phone">
                        {formatMessage({
                            ...messages.number
                        })}
                    </a>
                </div>
                <div className={styles.email}>
                    <EmailIcon />
                    <a
                        href={`mailto:${email ||
                            formatMessage({
                                ...messages.email
                            })}`}
                        data-ga-id="footer__email">
                        {email ||
                            formatMessage({
                                ...messages.email
                            })}
                    </a>
                </div>

                {!hideCta && (
                    <div className={styles.apply}>
                        {ctaButton && ctaButton.href ? (
                            <Button href={ctaButton.href} {...ctaButtonProps}>
                                {ctaButtonText}
                            </Button>
                        ) : (
                            <Button
                                href={loggedIn ? '/my_account/' : '#compare'}
                                onClick={() => {
                                    trackFooterCta(ctaButtonText, productName);
                                }}
                                {...ctaButtonProps}>
                                {ctaButtonText}
                            </Button>
                        )}
                    </div>
                )}
            </div>
        </section>
    );
};

export default FooterContact;
