import React, { useContext } from 'react';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import StepZeroModal from './StepZeroModal/StepZeroModal';
import HelpModal from './HelpModal/HelpModal';
import FilterMobileModal from './FilterMobileModal/FilterMobileModal';
import { MODALS } from 'aphrodite-shared/util/iwocaConstants';
import CopyModal from './CopyModal/CopyModal';

const IwModals = ({ openModal, maximumLoanAmount }) => {
    const context = useContext(IwContext);

    return (
        <>
            <StepZeroModal
                isOpen={
                    context.activeModals.includes(MODALS.signupStepZero) ||
                    openModal === MODALS.signupStepZero
                }
                maximumLoanAmount={maximumLoanAmount}
                prefilledOptions={context.prefilledOptions}
            />
            <HelpModal
                isOpen={
                    context.activeModals.includes(MODALS.contactHelp) ||
                    openModal === MODALS.contactHelp
                }
            />
            <FilterMobileModal
                isOpen={
                    context.activeModals.includes(
                        MODALS.covidFilterArticleMobile
                    ) || openModal === MODALS.covidFilterArticleMobile
                }
            />
            <CopyModal
                isOpen={
                    context.activeModals.includes(MODALS.copyModal) ||
                    openModal === MODALS.copyModal
                }
            />
        </>
    );
};

export default IwModals;
