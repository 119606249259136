import { defineMessages } from 'react-intl';

export default defineMessages({
    phone_number: {
        id: '_.generic_landing__navbar_phone_number',
        defaultMessage: '020 3778 0274'
    },
    phone_number_link: {
        id: '_.generic_landing__navbar_phone_number_link',
        defaultMessage: 'tel:+442037780274'
    },
    apply: {
        id: '_.generic_landing__navbar_apply',
        defaultMessage: 'Apply now'
    },
    check_eligibility: {
        id: '_.generic_landing__navbar_check_eligibility',
        defaultMessage: 'Check eligibility'
    },
    login: {
        id: '_.navbar_login',
        defaultMessage: 'Log in'
    },
    my_account: {
        id: '_.generic_landing__navbar_my_account',
        defaultMessage: 'My account'
    }
});
