import React from 'react';

const CloseIcon = ({ onClick, className, svgClassName, fill = 'none' }) => {
    return (
        <div onClick={onClick} className={className} data-testid="CloseIcon">
            <svg
                height="36px"
                width="36px"
                viewBox="0 0 24 24"
                fill="none"
                className={svgClassName}
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.41421 6.18174e-08L12.7279 11.3137L11.3137 12.7279L0 1.41421L1.41421 6.18174e-08Z"
                    fill={fill}
                />
                <path
                    d="M0 11.3137L11.3137 0L12.7279 1.41421L1.41421 12.7279L0 11.3137Z"
                    fill={fill}
                />
            </svg>
        </div>
    );
};

export default CloseIcon;
