import React from 'react';

import FooterLinks from './FooterLinks/FooterLinks';
import FooterContact from './FooterContact/FooterContact';
import FooterDisclaimer from './FooterDisclaimer/FooterDisclaimer';

const Footer = ({ ctaButton, phoneNumberLink, email }) => {
    return (
        <footer>
            <FooterLinks />
            <FooterContact
                ctaButton={ctaButton}
                phoneNumberLink={phoneNumberLink}
                email={email}
            />
            <FooterDisclaimer />
        </footer>
    );
};

export default Footer;
