import React from 'react';
import classnames from 'classnames';
import styles from '../NavBar.module.scss';

const SubNav = ({ subNavData }) => (
    <div className={styles.SubNav}>
        <div className={classnames(styles.paneContainer, styles.subNavContent)}>
            {subNavData.map((subNavItem) => (
                <a className={styles.SubNavItem} href={subNavItem.link}>
                    {subNavItem.text}
                </a>
            ))}
        </div>
    </div>
);

export default SubNav;
