import React, { useEffect, useContext } from 'react';
import ReactModal from 'react-modal';
import { useStaticQuery, navigate, graphql } from 'gatsby';
import styles from './CopyModal.module.scss';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { MODALS } from 'aphrodite-shared/util/iwocaConstants';
import { postToDataLayer } from '../../../util/googleTracking';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import Cross from '../svg/cross.svg';
import CloseIcon from '../../../SVGComponents/CloseIcon';

function useCopy() {
    const { contentfulPageTextOnly } = useStaticQuery(
        graphql`
            {
                contentfulPageTextOnly(
                    id: { eq: "e76a66be-e323-56a0-b242-a382f6516439" }
                ) {
                    title
                    url
                    allowSearchEngineScraping
                    metaDescription
                    textContent {
                        ... on ContentfulSectionTextOnly {
                            id
                            text {
                                json
                            }
                        }
                    }
                }
            }
        `
    );

    return contentfulPageTextOnly;
}

const CopyModal = ({ isOpen }) => {
    const context = useContext(IwContext);

    function closeModal() {
        const { pathname, hash } = window.location;
        postToDataLayer({ event: 'closedCopyModal' });
        context.closeModal(MODALS.copyModal);
        if (hash.length > 0) {
            navigate(pathname);
        }
        setTimeout(() => {
            window.scrollTo(0, 940);
        }, 250);
    }

    useEffect(() => {
        checkHash();
        window.addEventListener('hashchange', checkHash, false);
        return () => {
            window.removeEventListener('hashchange', checkHash, false);
        };
    }, []);

    function checkHash() {
        if (window.location.hash === '#criteria') {
            context.openModal('copyModal');
        }
    }

    const data = useCopy();
    const { textContent } = data;

    return (
        <div>
            <ReactModal
                onRequestClose={closeModal}
                className={styles.modal}
                isOpen={isOpen}>
                <div className={styles.CopyModal}>
                    <div className={styles.crossContainer} onClick={closeModal}>
                        <CloseIcon
                            src={Cross}
                            alt="Close icon"
                            className={styles.cross}
                        />
                    </div>
                    <div className={styles.content}>
                        {textContent.map((item) => {
                            switch (item.__typename) {
                                case 'ContentfulSectionTextOnly':
                                    return documentToReactComponents(
                                        item.text.json
                                    );
                                default:
                                    return null;
                            }
                        })}
                    </div>
                </div>
            </ReactModal>
        </div>
    );
};

export default CopyModal;
