import React from 'react';

const LockIcon = ({ className }) => {
    return (
        <svg
            width="9"
            height="12"
            viewBox="0 0 9 12"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.05814 12C8.5814 12 9 11.5714 9 11.0357V5.78571C9 5.25 8.5814 4.875 8.05814 4.875V3.58929C8.05814 1.60714 6.48837 0 4.5 0C2.56395 0 0.994186 1.60714 0.994186 3.58929V4.875H0.941861C0.418605 4.875 0 5.25 0 5.78571V11.0357C0 11.5714 0.418605 12 0.941861 12H8.05814ZM4.5 1.55357C5.59884 1.55357 6.48837 2.46429 6.48837 3.58929V4.82143H2.51163V3.58929C2.51163 2.46429 3.40116 1.55357 4.5 1.55357ZM4.5 10C5.32843 10 6 9.32843 6 8.5C6 7.67157 5.32843 7 4.5 7C3.67157 7 3 7.67157 3 8.5C3 9.32843 3.67157 10 4.5 10Z"
                fill="#595959"
            />
        </svg>
    );
};

export default LockIcon;
