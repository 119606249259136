import React from 'react';
import classnames from 'classnames';

import Cross from '../svg/cross.svg';
import CloseIcon from '../../../SVGComponents/CloseIcon';

import styles from './CompareProducts.module.scss';
import Button from 'aphrodite-shared/ui/Button/Button';
import TelIcon from '../../../SVGComponents/TelIconSVG';

const CompareProducts = ({ closeModal }) => {
    return (
        <div className={styles.CompareProducts}>
            <div className={styles.crossContainer} onClick={closeModal}>
                <CloseIcon
                    src={Cross}
                    alt="Close icon"
                    className={styles.cross}
                />
            </div>
            <div className={styles.mobileTelephone}>
                <TelIcon />
                <a href="tel:+442081068821">020 8106 8821</a>
            </div>
            <div className={styles.header}>
                <h2>Looking for help?</h2>
                <h2>Looking to grow?</h2>
            </div>
            <div className={styles.productTileContainer}>
                <div className={classnames(styles.productTile, styles.rls)}>
                    <p className={styles.mobile}>Looking for help?</p>
                    <h3>Recovery Loan</h3>
                    <p className={styles.desktop}>
                        Government-backed loan for businesses struggling due to
                        coronavirus.
                    </p>
                    <hr />
                    <p className={styles.mobile}>
                        Government-backed loans{' '}
                        <strong>from £25,001 to £750,000 up to 5 years</strong>,
                        for businesses struggling due to coronavirus.{' '}
                        <strong>No personal guarantee</strong> up to £250,000
                    </p>
                    <ul className={styles.benefits}>
                        <li className={styles.benefit}>
                            <p className={styles.description}>Loans from</p>
                            <p className={styles.value}>£25,001 to £750,000</p>
                        </li>
                        <li className={styles.benefit}>
                            <p className={styles.description}>Borrow up to</p>
                            <p className={styles.value}>5 years</p>
                        </li>
                        <li className={styles.benefit}>
                            <p className={styles.altLeft}>
                                <strong>No personal guarantee</strong> up to
                                £250,000
                            </p>
                        </li>
                    </ul>
                    <Button
                        colour="primary"
                        onClick={() => {
                            window.location = '/recovery-loan-scheme/#apply';
                        }}
                        dataGaId="product_comparison_modal__rls__apply">
                        Apply for a Recovery Loan
                    </Button>
                </div>
                <div className={classnames(styles.productTile, styles.flexi)}>
                    <p className={styles.mobile}>Looking to grow?</p>
                    <h3>Flexi-Loan</h3>
                    <p className={styles.desktop}>
                        Our original loan: apply fast and top up whenever you
                        need to.
                    </p>
                    <hr />
                    <p className={styles.mobile}>
                        Borrow{' '}
                        <strong>
                            from £1,000 to £500,000 for over 12 months
                        </strong>
                        . Get a decision in 24 hours and top up whenever you
                        need to.
                    </p>
                    <ul className={styles.benefits}>
                        <li className={styles.benefit}>
                            <p className={styles.description}>Loans from</p>
                            <p className={styles.value}>£1,000 to £500,000</p>
                        </li>
                        <li className={styles.benefit}>
                            <p className={styles.description}>Borrow over</p>
                            <p className={styles.value}>12 or 24 months</p>
                        </li>
                        <li className={styles.benefit}>
                            <p className={styles.description}>Decisions in</p>
                            <p className={styles.value}>24 hours</p>
                        </li>
                    </ul>
                    <Button
                        colour="secondaryDark"
                        onClick={() => {
                            window.location.hash = '#apply';
                        }}
                        dataGaId="product_comparison_modal__FL__cta">
                        Apply for a Flexi-Loan
                    </Button>
                </div>
            </div>
            <div className={styles.footer}>
                <p className={styles.footerCopy}>
                    Need help?&nbsp;<strong>Give us a call on</strong>&nbsp;
                    <section className={styles.telephone}>
                        &nbsp;
                        <TelIcon />
                        <a href="tel:+442081068821">020 8106 8821</a>
                    </section>
                </p>
            </div>
        </div>
    );
};

export default CompareProducts;
