import React from 'react';

const LinkedInIcon = () => (
    <a
        href="https://www.linkedin.com/company/iwoca/"
        target="_blank"
        rel="noopener noreferrer">
        <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="20"
                cy="20.0957"
                r="19"
                stroke="white"
                strokeWidth="2"
            />
            <g clipPath="url(#clip0)">
                <path
                    d="M15.845 14.0257C15.845 15.084 14.9767 15.9556 13.9225 15.9556C12.8682 15.9556 12 15.084 12 14.0257C12 12.9673 12.8682 12.0957 13.9225 12.0957C14.9767 12.0957 15.845 12.9673 15.845 14.0257Z"
                    fill="white"
                />
                <path
                    d="M15.5349 17.3877H12.248V28.0959H15.5349V17.3877Z"
                    fill="white"
                />
                <path
                    d="M27.9992 22.2435V28.0956H24.7123V22.8661C24.7123 21.6209 24.7123 20.0022 22.9759 20.0022C21.2395 20.0022 20.9914 21.3719 20.9914 22.7415V28.0334H17.6426V17.3875H20.8054V18.8194H20.8674C21.3015 18.01 22.4178 17.0762 24.0302 17.0762C27.379 17.1384 27.9992 19.3797 27.9992 22.2435Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0">
                    <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(12 12.0957)"
                    />
                </clipPath>
            </defs>
        </svg>
    </a>
);

export default LinkedInIcon;
