import React, { useContext } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import NumberFormat from 'react-number-format';
import classnames from 'classnames';
import {
    submitSignupForm,
    sendApplicationDataToGA,
    debouncedEmailValidation,
    validateEmailUniqueness
} from '../../../../components/Modals/ApplyModalHelpers';
import { SIGNUP_PAGE_URL } from 'aphrodite-shared/util/iwocaConstants';
import { Button } from '@iwoca/orion';
import ErrorIcon from '../../../../SVGComponents/ErrorIcon';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import ConfirmedIcon from 'aphrodite-shared/SVGComponents/ConfirmedIcon';

import styles from './StepZeroForm.module.scss';
import { logErrorToDataLayer } from '../../../../util/googleTracking';
import StepZeroProductApplicationSchema from './StepZeroProductApplicationValidation';
import { getProductConfig } from './StepZeroFormHelpers';
import { useProductName, getProductName } from '../StepZeroModalHelpers';
import {
    Amplitude,
    AmplitudeSignup,
    AmplitudeWebsite
} from '@iwoca/frontend-tracking-library';
import { AMPLITUDE_EVENT_NAMES } from 'aphrodite-shared/util/tracking/amplitudeEvents';
import { getCurrentLocale } from 'aphrodite-shared/util/localization';

export default function StepZeroForm({ prefilledOptions }) {
    const context = useContext(IwContext);
    const productName = useProductName();
    const { minAmount, maxAmount } = getProductConfig(productName);

    const trackStepZeroCta = () => {
        const productCode = getProductName(productName);
        Amplitude.track({
            ...AmplitudeWebsite.START_APPLICATION,
            payload: {
                requestedProductType: productCode
            }
        });
    };

    const handleSubmitForm = async (values, formik) => {
        if (!values['applicantEmailAddress']) {
            formik.setSubmitting(false);
            return;
        }

        try {
            await sendApplicationDataToGA(values);
            await submitSignupForm(values, productName);
            Amplitude.track(
                AmplitudeSignup.signupEventCreator({
                    event:
                        AMPLITUDE_EVENT_NAMES.NEW_APPLICATION_MODAL_SUBMITTED,
                    locale: getCurrentLocale(),
                    payload: {
                        url: window?.location?.href
                    }
                })
            );
            window.location = SIGNUP_PAGE_URL[process.env.GATSBY_COUNTRY_BUILD];
        } catch (error) {
            context.showApiError('Hmm, something went wrong. Try again?');
            logErrorToDataLayer('Step Zero - Form submission error');

            formik.setSubmitting(false);
            if (typeof window !== 'undefined') {
                window.Raven.captureException(error);
            }
        }
    };

    const initialValues = {
        applicantEmailAddress: '',
        requestedAmount: context.borrowAmount,
        email_marketing_opt_in: false,
        ...prefilledOptions
    };

    const applyCTA =
        productName === null ? 'Apply now' : `Apply for a ${productName}`;

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, formik) => {
                handleSubmitForm(values, formik);
                trackStepZeroCta();
            }}
            validationSchema={StepZeroProductApplicationSchema(productName)}
            validateOnChange={false}>
            {(props) => {
                const { isSubmitting } = props;

                return (
                    <Form data-testid="stepZeroForm">
                        <div className={styles.formGroupContainer}>
                            <EmailField form={props} />
                            <AmountField
                                minAmount={minAmount}
                                maxAmount={maxAmount}
                            />
                        </div>

                        <div className={styles.bottom}>
                            <MarketingCheckbox />
                            <div className={styles.buttonContainer}>
                                <Button
                                    type="submit"
                                    variant="primary"
                                    disabled={isSubmitting}
                                    className={classnames(styles.submitButton, {
                                        [styles.submitting]: isSubmitting
                                    })}>
                                    {isSubmitting ? 'Submitting' : applyCTA}
                                </Button>
                            </div>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
}

const EmailField = ({ form }) => {
    return (
        <div className={styles.formGroup}>
            <label htmlFor="applicantEmailAddress">
                What is your business email address?
            </label>
            <div className={`fs-block ${styles.input}`}>
                <Field
                    name="applicantEmailAddress"
                    validate={() => {
                        if (form.values['applicantEmailAddress'] === '') {
                            return 'Please enter a valid email address';
                        }
                        return form.errors['applicantEmailAddress'];
                    }}>
                    {({ form, field }) => (
                        <>
                            <input
                                {...field}
                                id="applicantEmailAddress"
                                placeholder="Enter your business email"
                                type="email"
                                className={`fs-block
                                ${form.errors.applicantEmailAddress &&
                                    form.touched.applicantEmailAddress &&
                                    styles.error}`}
                                onChange={async (e) => {
                                    await form.handleChange(e);
                                    form.setStatus('EMAIL_VALIDATION_PENDING');
                                    await debouncedEmailValidation(form, e);
                                }}
                                onBlur={async (e) => {
                                    await form.handleBlur(e);
                                    await validateEmailUniqueness(
                                        e.target.value
                                    );
                                    debouncedEmailValidation.flush();
                                }}
                            />
                            {form.status === 'EMAIL_VALIDATED' && (
                                <div className={styles.emailCheck}>
                                    <ConfirmedIcon
                                        className={styles.emailCheckIcon}
                                    />
                                </div>
                            )}
                        </>
                    )}
                </Field>
                <FormErrorMessage name="applicantEmailAddress" />
            </div>
        </div>
    );
};

const AmountField = ({ minAmount, maxAmount }) => {
    const getPlaceholder = (minAmount, maxAmount) => {
        if (minAmount > 0) {
            return `Between £${Number(
                minAmount.toFixed(1)
            ).toLocaleString()} and £${Number(
                maxAmount.toFixed(1)
            ).toLocaleString()}`;
        }

        return `Any amount, up to £${Number(
            maxAmount.toFixed(1)
        ).toLocaleString()}`;
    };

    return (
        <div className={styles.formGroup}>
            <label htmlFor="requestedAmount">How much would you like?</label>
            <div className={styles.input}>
                <Field name="requestedAmount">
                    {({ form, field }) => (
                        <NumberFormat
                            id="requestedAmount"
                            inputMode="numeric"
                            type="tel"
                            value={field.value}
                            placeholder={getPlaceholder(minAmount, maxAmount)}
                            className={
                                form.errors.requestedAmount &&
                                form.touched.requestedAmount &&
                                styles.error
                            }
                            thousandSeparator=","
                            decimalSeparator="."
                            decimalScale={0}
                            prefix="£"
                            allowNegative={false}
                            isAllowed={({ formattedValue, floatValue }) => {
                                if (formattedValue === '') return true;

                                return floatValue <= maxAmount;
                            }}
                            onValueChange={(values) => {
                                form.setFieldValue(
                                    'requestedAmount',
                                    values.floatValue ? values.floatValue : null
                                );
                            }}
                        />
                    )}
                </Field>
                <FormErrorMessage name="requestedAmount" />
            </div>
        </div>
    );
};

const MarketingCheckbox = () => {
    return (
        <div className={styles.marketingOption}>
            <Field name="email_marketing_opt_in">
                {({ field }) => (
                    <input
                        {...field}
                        id="email_marketing_opt_in"
                        type="checkbox"
                        checked={field.value}
                    />
                )}
            </Field>
            <label htmlFor="email_marketing_opt_in">
                <strong>Sign up for updates</strong> – we’ll email offers and
                stories from other small businesses.
            </label>
        </div>
    );
};

const FormErrorMessage = ({ name, ...restProps }) => (
    <ErrorMessage name={name}>
        {(msg) => (
            <div className={styles.inputFeedback} {...restProps}>
                <ErrorIcon />
                <p dangerouslySetInnerHTML={{ __html: msg }} />
            </div>
        )}
    </ErrorMessage>
);
