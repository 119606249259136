import React from 'react';
import styles from './FooterDisclaimer.module.scss';

import { useIntl } from 'react-intl';
import messages from '../Footer.messages';

import { getCountryBuild } from '../../../util/localization';

const FooterDisclaimer = () => {
    const { formatMessage } = useIntl();
    return (
        <section className={styles.background}>
            <div className={styles.FooterDisclaimer}>
                {getCountryBuild() === 'uk' && (
                    <p>
                        iwoca Ltd, 3rd Floor 101 New Cavendish Street London,
                        W1W 6XH
                    </p>
                )}
                <p>
                    {formatMessage({
                        ...messages.disclaimer
                    })}
                </p>
            </div>
        </section>
    );
};

export default FooterDisclaimer;
