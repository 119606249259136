// Written using ES5 modules for use in gastby-node.js please do not update
const contentHubHelper = {
    getContenthubArticleFullSlug: function(category, slug) {
        let url;
        switch (category) {
            case 'Finance':
                url = `/finance-explained${slug}`;
                break;
            case 'Cash Flow':
                url = `/finance-explained/cash-flow${slug}`;
                break;
            case 'Invoicing':
                url = `/finance-explained/invoicing${slug}`;
                break;
            case 'Payments':
                url = `/finance-explained/payments${slug}`;
                break;
            case 'Small business loans':
                url = `/small-business-loans${slug}`;
                break;
            case 'News':
                url = `/news${slug}`;
                break;
            case 'Podcast':
                url = `/podcast${slug}`;
                break;
            case 'Customer stories':
                url = `/customer-stories${slug}`;
                break;
            case 'Engineering and Data Science':
            case 'Product and Design':
            case 'People of iwoca':
                url = `/inside-iwoca${slug}`;
                break;
            default:
                url = `/insights${slug}`;
        }
        return url;
    },
    getContenthubCategoryFullSlug: function(category, careersCategorySlug) {
        let url;

        switch (category) {
            case 'Covid-19':
                url = `/covid-19/`;
                break;
            case 'News':
                url = `/news/`;
                break;
            case 'Podcast':
                url = `/podcast/`;
                break;
            case 'Finance':
                url = `/finance-explained/`;
                break;
            case 'Small business loans':
                url = `/small-business-loans/`;
                break;
            case 'Customer stories':
                url = `/customer-stories/`;
                break;
            case 'Guides':
                url = `/insights/`;
                break;
            case 'Cash Flow':
                url = `/finance-explained/cash-flow/`;
                break;
            case 'Invoicing':
                url = `/finance-explained/invoicing/`;
                break;
            case 'Payments':
                url = `/finance-explained/payments/`;
                break;
            case 'Engineering and Data Science':
            case 'Product and Design':
            case 'People of iwoca':
                url = `/inside-iwoca${careersCategorySlug}`;
                break;
            default:
                url = `/insights/`;
        }
        return url;
    }
};

module.exports = contentHubHelper;
