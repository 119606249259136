import {
    CASH_ADVANCE_PRODUCT_NAME,
    LIGHTNING_LOAN_PRODUCT_NAME,
    RECOVERY_LOAN_PRODUCT_NAME
} from '../StepZeroModalHelpers';

const FLEXI_LOAN_BOUNDS = {
    minAmount: 0,
    maxAmount: 500000
};

const LIGHTNING_LOAN_BOUNDS = {
    minAmount: 0,
    maxAmount: 15000
};

const RECOVERY_LOAN_BOUNDS = {
    minAmount: 25001,
    maxAmount: 750000
};

const CASH_ADVANCE_BOUNDS = {
    minAmount: 1000,
    maxAmount: 100000
};

export function getProductConfig(productName) {
    if (productName === LIGHTNING_LOAN_PRODUCT_NAME)
        return LIGHTNING_LOAN_BOUNDS;

    if (productName === RECOVERY_LOAN_PRODUCT_NAME) return RECOVERY_LOAN_BOUNDS;

    if (productName === CASH_ADVANCE_PRODUCT_NAME) return CASH_ADVANCE_BOUNDS;

    return FLEXI_LOAN_BOUNDS;
}
