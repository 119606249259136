import uuid from 'uuid';
import lodashGet from 'lodash/get';
import lodashMerge from 'lodash/merge';
import lodashDebounce from 'lodash/debounce';
import yup from 'yup';
import { postToDataLayer } from '../../util/googleTracking';

import {
    lendingAPIPutApplication,
    lendingAPIGetEmailAvailability,
    lendingAPIPostStateV1,
    lendingAPIPutStateV1
} from '../../api/lendingApi';
import { rawIntl } from 'aphrodite-shared/IwIntl/IwIntlProvider';
import messages from '../IwModals/StepZeroModal/StepZeroForm/StepZeroForm.messages.js';
import {
    CASH_ADVANCE_PRODUCT_NAME,
    FLEXI_LOAN_PRODUCT_NAME,
    RECOVERY_LOAN_PRODUCT_NAME
} from '../IwModals/StepZeroModal/StepZeroModalHelpers';

export function getMultiProductSplitTest(apiResponse) {
    const splitTests = lodashGet(apiResponse, 'data.user_split_tests').find(
        (splitTest) =>
            Object.keys(splitTest).includes('uses_new_lapi_endpoints')
    );

    return lodashGet(splitTests, 'uses_new_lapi_endpoints');
}

export async function submitSignupForm(signupValues, product) {
    // For the product agnostic modal, productName is set to null in useProductName to render the correct UI
    // We can't post null as a requested_product so default to Flexi-Loan
    if (!product) {
        product = FLEXI_LOAN_PRODUCT_NAME;
    }

    try {
        const signupDataWithoutProduct = serialiseStateWithoutProduct(
            signupValues
        );
        const postStateResponse = await lendingAPIPostStateV1(
            signupDataWithoutProduct
        );

        const stateKey = postStateResponse.data.state_key;

        const serialisedApplicationSignupValues = serialiseApplicationEndpoint(
            signupValues
        );

        await lendingAPIPutApplication(
            stateKey,
            serialisedApplicationSignupValues
        );

        const stateProductData = serialiseStateProduct(signupValues, product);

        const serialisedSignupValues = lodashMerge(
            {},
            stateProductData,
            signupDataWithoutProduct
        );

        await lendingAPIPutStateV1(stateKey, serialisedSignupValues);
    } catch (error) {
        throw new Error(error);
    }
}

export async function sendApplicationDataToGA(values) {
    const applicationData = {
        event: 'submitSignupStep0',
        applicationData: (({ email, ...userApplicationData }) =>
            userApplicationData)(values)
    };
    postToDataLayer(applicationData);
}

export function serialiseApplicationEndpoint(values) {
    const productValues = getProductValues(values);
    return {
        requested_products: [productValues]
    };
}

export const debouncedEmailValidation = lodashDebounce(emailValidation, 750, {
    trailing: true
});

export async function emailValidation(form, event) {
    // NOTICE: Errors get only displayed when touched

    // Loading required error messaged
    const errorMessages = {
        invalidEmail: rawIntl.formatMessage({
            ...messages.please_enter_a_valid_email_address
        }),
        nonUniqueEmail:
            'Email already taken - <a href="/login" data-ga-id="step0_email_already_exists__login">log in</a>'
    };

    // Setup needed variables
    let errorMessage;
    let formStatus;
    const currentEmailInput = lodashGet(event, 'target.value');

    if (!currentEmailInput) return;
    if (currentEmailInput.length < 5) return; // Minimum email x@x.x

    // Validate if input matches email pattern
    const validEmail = await yup
        .string()
        .email()
        .isValid(currentEmailInput);

    if (!validEmail) {
        errorMessage = errorMessages.invalidEmail;
        formStatus = 'EMAIL_INVALID';
    }

    // If input matches email pattern, run it against the API
    if (validEmail) {
        const uniqueEmail = await validateEmailUniqueness(currentEmailInput);

        if (!uniqueEmail) {
            errorMessage = errorMessages.nonUniqueEmail;
            formStatus = 'EMAIL_INVALID';
        }
    }

    // Use formStatus for additional visiual hints
    if (!formStatus) {
        formStatus = 'EMAIL_VALIDATED';
    }

    // Persist values to form
    await form.setStatus(formStatus);
    await form.setFieldError('applicantEmailAddress', errorMessage);
}
export async function validateEmailUniqueness(email) {
    if (!email) return true;

    try {
        const data = await lendingAPIGetEmailAvailability(email);

        const available = data.data.available;
        if (available) return true;
        return false;
    } catch (error) {
        if (
            error.data &&
            error.data.errors[0].code === 'URLQueryParameterError'
        ) {
            return true;
        }
        return false;
    }
}

export function serialiseStateWithoutProduct(values) {
    return {
        application: {
            people: [
                {
                    last_name: '',
                    uid: uuid.v4(),
                    roles: ['applicant'],
                    first_name: '',
                    emails: [
                        {
                            email: values.applicantEmailAddress,
                            marketing_opt_in: {
                                agreed: values.email_marketing_opt_in
                            },
                            uid: uuid.v4(),
                            type: 'primary'
                        }
                    ]
                }
            ]
        },
        ui: {
            version: {
                bundleHashName:
                    // eslint-disable-next-line no-undef
                    creationTimestamp
            }
        }
    };
}

export function serialiseStateProduct(values, product) {
    if (product === RECOVERY_LOAN_PRODUCT_NAME) {
        return {
            application: {
                requested_products: {
                    recovery_loan: {
                        approval: {
                            amount: parseInt(values.requestedAmount)
                        }
                    }
                }
            }
        };
    }

    if (product === CASH_ADVANCE_PRODUCT_NAME) {
        return {
            application: {
                requested_products: {
                    merchant_cash_advance: {
                        approval: {
                            amount: parseInt(values.requestedAmount)
                        }
                    }
                }
            }
        };
    }

    return {
        application: {
            requested_products: {
                credit_facility: {
                    approval: {
                        amount: parseInt(values.requestedAmount)
                    }
                }
            }
        }
    };
}

export function getProductValues(values) {
    return {
        amount: parseInt(values.requestedAmount)
    };
}
