import React from 'react';
import { Helmet } from 'react-helmet';
import { getFullIwocaUrl } from 'aphrodite-shared/util/helpers';

const MetaData = (props) => {
    const {
        titleTag,
        metaDescription,
        allowSearchEngineScraping = true,
        includeTitlePrefixSuffix = true,
        url,
        canonicalTag,
        slug,
        articleImage,
        structuredData
    } = props.metaData;

    const articleOrPageUrl = slug || url || canonicalTag;

    const canonicalUrl = `https://www.${getFullIwocaUrl(
        `${articleOrPageUrl}`
    )}`;

    return (
        <Helmet>
            <link rel="canonical" href={canonicalUrl} />
            {titleTag && (
                <title>{`${titleTag} ${
                    includeTitlePrefixSuffix ? '| iwoca' : ''
                }`}</title>
            )}
            {titleTag && <meta property="og:title" content={titleTag} />}
            {metaDescription && (
                <meta name="description" content={metaDescription} />
            )}
            {metaDescription && (
                <meta property="og:description" content={metaDescription} />
            )}
            {articleOrPageUrl && (
                <meta property="og:url" content={articleOrPageUrl} />
            )}
            {articleImage && (
                <meta property="og:image" content={articleImage.file.url} />
            )}
            {articleImage && (
                <meta
                    property="twitter:image"
                    content={articleImage.file.url}
                />
            )}
            {!allowSearchEngineScraping && (
                <meta name="robots" content="noindex" />
            )}
            {structuredData &&
                structuredData.map((item, i) => {
                    return (
                        <script type="application/ld+json" key={`script-${i}`}>
                            {JSON.stringify(item)}
                        </script>
                    );
                })}
            <meta name="twitter:card" content="summary"></meta>
            <meta name="twitter:site" content="@iwoca" />
        </Helmet>
    );
};

export default MetaData;
