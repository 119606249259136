import React from 'react';

const FacebookIcon = () => (
    <a
        href="https://www.facebook.com/iwoca/"
        target="_blank"
        rel="noopener noreferrer">
        <svg
            width="40"
            height="41"
            viewBox="0 0 40 41"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="20"
                cy="20.0957"
                r="19"
                stroke="white"
                strokeWidth="2"
            />
            <path
                d="M21.1926 28.0956V20.7973H23.5513L23.9044 17.953H21.1926V16.137C21.1926 15.3135 21.4128 14.7522 22.5499 14.7522L24 14.7516V12.2077C23.7491 12.1731 22.8883 12.0957 21.8869 12.0957C19.7961 12.0957 18.3647 13.4212 18.3647 15.8554V17.953H16V20.7973H18.3647V28.0957L21.1926 28.0956Z"
                fill="white"
            />
        </svg>
    </a>
);

export default FacebookIcon;
