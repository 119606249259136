import yup from 'yup';
import { getProductConfig } from './StepZeroFormHelpers';

export default function StepZeroProductApplicationSchema(productName) {
    const { minAmount, maxAmount } = getProductConfig(productName);

    return yup.object({
        requestedAmount: yup
            .number()
            .typeError('Please enter a requested amount')
            .min(minAmount)
            .max(maxAmount)
            .required('Please enter a requested amount'),
        email_marketing_opt_in: yup.bool().required()
    });
}
