import React from 'react';
import ReactMarkdown from 'react-markdown/with-html';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './FooterLinks.module.scss';

import IwocaLogoSVG from '../../../SVGComponents/IwocaLogoSVG';
import BBBLogo from '../bbb-working.png';
import IwLink from '../../IwLink/IwLink';

function useFooterData() {
    const { contentfulFooterLinks } = useStaticQuery(
        graphql`
            {
                contentfulFooterLinks {
                    cbilsCopy
                    cbilsCopyMobile
                    cbilsImage {
                        fluid(maxWidth: 300, quality: 90) {
                            ...GatsbyContentfulFluid_withWebp_noBase64
                        }
                    }
                    section1 {
                        title
                        url
                        dataGaId
                    }
                    section1Title
                    section2 {
                        title
                        url
                        dataGaId
                    }
                    section2Title
                    section3 {
                        title
                        url
                        dataGaId
                    }
                    section3Title
                    section4 {
                        title
                        url
                        dataGaId
                    }
                    section4Title
                    section5 {
                        title
                        url
                        dataGaId
                    }
                    section5Title
                    section6 {
                        title
                        url
                        dataGaId
                    }
                    section6Title
                }
            }
        `
    );

    return contentfulFooterLinks;
}

const FooterLinks = () => {
    const footerData = useFooterData();
    let footerElements = [];
    for (let key in footerData) {
        if (Array.isArray(footerData[key])) {
            // Because of the way we pull in title and linked blogs from contentful we need to filter it out
            const title = footerData[key + 'Title'];
            footerElements.push(
                <LinkColumn
                    key={key}
                    column={footerData[key]}
                    title={title}
                    dataGaId={footerData[key]['dataGaId']}
                />
            );
        }
    }

    return (
        <section className={styles.background}>
            <div className={styles.FooterLinks}>
                <div className={styles.logoContainer}>
                    <IwLink to="/">
                        <IwocaLogoSVG className={styles.logo} />
                    </IwLink>
                </div>
                <div className={styles.footerLinksContainer}>
                    {footerElements}
                </div>
            </div>
        </section>
    );
};

const LinkColumn = ({ column, title }) => {
    const linkGaId = 'footer__link';

    return (
        <div className={styles.footerLinksList}>
            <h5>{title}</h5>
            {column
                .filter((item) => {
                    if (
                        item.title === 'CBILS FAQs' ||
                        item.title === 'CBILS Loans'
                    ) {
                        return false;
                    }
                    return true;
                })
                .map((link, i) => {
                    return (
                        <IwLink
                            key={`footer-link-${i}`}
                            to={link.url}
                            data-ga-id={
                                link.dataGaId ? link.dataGaId : linkGaId
                            }>
                            {link.title}
                        </IwLink>
                    );
                })}
        </div>
    );
};

export default FooterLinks;
