import React from 'react';
import IwLink from '../IwLink/IwLink';
import classNames from 'classnames';
import styles from './Button.module.scss';

function Button({
    href,
    to,
    isDownloadable = false,
    title,
    type,
    disabled,
    className,
    children,
    outline,
    onClick,
    colour,
    icon,
    size,
    newTab,
    dataGaId,
    dataTestid,
    description,
    buttonClass
}) {
    const outlineClassName = outline ? styles.outlinedButton : styles.button;

    function handleOnClick(e) {
        e.preventDefault();
        onClick();
    }

    function handleOnClickWithHref(e) {
        onClick();
    }

    function generateClassName() {
        return classNames(
            outlineClassName,
            buttonClass,
            styles[`${colour}Button`],
            styles[`${size}Button`]
        );
    }

    if (href) {
        return (
            <div className={classNames(className, styles.buttonWrapper)}>
                <a
                    href={href}
                    title={title}
                    className={generateClassName()}
                    role="button"
                    target={newTab ? '_blank' : '_self'}
                    onClick={
                        onClick
                            ? (e) => {
                                  handleOnClickWithHref(e);
                              }
                            : null
                    }
                    data-ga-id={dataGaId}>
                    {icon}
                    {children}
                </a>
                {description && (
                    <div className={styles.buttonDescription}>
                        {description}
                    </div>
                )}
            </div>
        );
    }

    if (to) {
        return (
            <IwLink
                onClick={onClick}
                to={to}
                title={title && title}
                className={generateClassName()}
                role="button"
                data-ga-id={dataGaId}>
                {icon}
                {children}
            </IwLink>
        );
    }

    return (
        <div className={classNames(className, styles.buttonWrapper)}>
            <button
                title={title}
                type={type}
                disabled={disabled}
                className={generateClassName()}
                onClick={
                    onClick
                        ? (e) => {
                              handleOnClick(e);
                          }
                        : null
                }
                data-ga-id={dataGaId}
                data-testid={dataTestid}
                download={isDownloadable}>
                {icon}
                {children}
                {description && (
                    <div className={styles.buttonDescription}>
                        {description}
                    </div>
                )}
            </button>
        </div>
    );
}

export default Button;
