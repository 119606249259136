import React from 'react';

const HalfStar = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.5755 0.0800781H0.748535V19.0801H19.5755V0.0800781Z"
            fill="#DCDCE4"
        />
        <path
            d="M10.1681 0.0800781H0.748535V19.0801H10.1681V0.0800781Z"
            fill="#00B67A"
        />
        <path
            d="M16.0636 8.24357H11.559L10.168 3.91797L8.77703 8.24357H4.27246L7.91978 10.9205L6.5207 15.2461L10.168 12.5692L13.8153 15.2461L12.4243 10.9205L16.0636 8.24357Z"
            fill="white"
        />
        <path
            d="M12.7397 11.8969L12.4243 10.9175L10.168 12.5661L12.7397 11.8969Z"
            fill="#DCDCE4"
        />
    </svg>
);

export default HalfStar;
