import React, { useContext, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styles from './HelpModal.module.scss';
import ReactModal from 'react-modal';
import CallIcon from '../../../SVGComponents/CallIcon';
import CloseSVG from '../../../SVGComponents/CloseIcon';
import EmailIcon from '../../Footer/svg/EmailIcon';
import IwContext from 'aphrodite-shared/IwContext/IwContext';

ReactModal.defaultStyles.overlay.zIndex = '22';
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.8)';

const useHelpModal = () => {
    const { contentfulHelpModal } = useStaticQuery(graphql`
        query HelpModalQuery {
            contentfulHelpModal {
                headline
                subtext {
                    subtext
                }
                bodyContent {
                    bodyContent
                }
                alternativeHeadline
                emailLink {
                    linkText
                    url
                }
                callLink {
                    linkText
                    url
                }
                mondayToFriday
                saturday
            }
        }
    `);
    return contentfulHelpModal;
};

const HelpModal = ({ isOpen }) => {
    useEffect(() => {
        document.addEventListener('click', contentfulCloseModal);
        return () => {
            document.removeEventListener('click', contentfulCloseModal);
        };
    }, []);

    function contentfulCloseModal(e) {
        if (e.target.id === 'cLink') {
            closeModal();
        }
    }

    const {
        headline,
        subtext,
        bodyContent,
        alternativeHeadline,
        emailLink,
        callLink,
        mondayToFriday,
        saturday
    } = useHelpModal();

    const context = useContext(IwContext);

    const closeModal = function() {
        context.closeModal('contactHelp');
    };

    return (
        <ReactModal
            isOpen={isOpen}
            className={styles.HelpModal}
            onRequestClose={closeModal}>
            <div className={styles.ModalContent}>
                <CloseSVG
                    svgClassName={styles.svgClassName}
                    className={styles.CloseButton}
                    fill="black"
                    onClick={closeModal}
                />
                <div className={styles.ContactPanel}>
                    <h4>{alternativeHeadline}</h4>
                    <div className={styles.ContactInfo}>
                        <div className={styles.ContactDetails}>
                            <div className={styles.Email}>
                                <EmailIcon className={styles.EmailIcon} />
                                <p>
                                    Email us at{' '}
                                    <a href={emailLink.url}>
                                        {emailLink.linkText}
                                    </a>
                                </p>
                            </div>
                            <div className={styles.Call}>
                                <CallIcon className={styles.CallIcon} />{' '}
                                <p>
                                    Call us on{' '}
                                    <a href={callLink.url}>
                                        {callLink.linkText}
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className={styles.OpeningHours}>
                            <div className={styles.Hours}>{mondayToFriday}</div>
                            <div className={styles.Hours}>{saturday}</div>
                        </div>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default HelpModal;
