import React from 'react';
import { useIntl } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';
import FullStar from './FullStar';
import HalfStar from './HalfStar';
import Star from './Star';
import styles from './DynamicTrustpilot.module.scss';

const useTrustPilotData = () => {
    const { trustpilot } = useStaticQuery(
        graphql`
            {
                trustpilot {
                    ...trustPilot
                }
            }
        `
    );

    return trustpilot;
};

const DynamicTrustpilot = ({ className, dataGaId }) => {
    const trustpilot = useTrustPilotData();
    const { formatNumber } = useIntl();
    return (
        <a
            href="https://uk.trustpilot.com/review/iwoca.co.uk"
            target="_blank"
            rel="noopener noreferrer"
            data-ga-id={dataGaId}>
            <div className={className}>
                <div className={styles.StarsWrapper}>
                    <p className={styles.Text}>Excellent</p>
                    <FullStar />
                    <FullStar />
                    <FullStar />
                    <FullStar />
                    {getFinalStar(trustpilot.score.trustScore)}
                </div>
                <div className={styles.Details}>
                    <Star />
                    <p>
                        Trustpilot •{' '}
                        {formatNumber(trustpilot.numberOfReviews.total)} reviews{' '}
                    </p>
                </div>
            </div>
        </a>
    );
};

const getFinalStar = (score) => {
    if (score > 4.7) {
        return <FullStar />;
    } else {
        return <HalfStar />;
    }
};

export default DynamicTrustpilot;
