import { defineMessages } from 'react-intl';

export default defineMessages({
    number: {
        id: '_.footer__number',
        defaultMessage: '020 3778 0274'
    },
    email: {
        id: '_.footer__email',
        defaultMessage: 'contact@iwoca.co.uk'
    },
    apply: {
        id: '_.footer__apply',
        defaultMessage: 'Apply now'
    },
    disclaimerIwocaAddress: {
        id: '_.footer__disclaimer__iwoca__address',
        defaultMessage: `iwoca Ltd, 3rd Floor 101 New Cavendish Street London, W1W 6XH`
    },
    disclaimer: {
        id: '_.footer__disclaimer',
        defaultMessage: `iwoca Limited is authorised and regulated by the Financial Conduct Authority (reference number: 723378) in relation to regulated credit agreements and are a registered account information service provider (reference number: 791804). We're also registered with the Information Commissioners Office in relation to the processing of personal information (registration number: Z3007540). iwoca Limited is incorporated in England and Wales (company number: 07798925). Our registered office is 10 Queen Street Place, London, EC4R 1AG.`
    }
});
