import get from 'lodash/get';
import { useRef } from 'react';

export const STATIC_DOMAIN = 'https://signup-static.iwoca.com';

async function fetchAssetVersion() {
    return new Promise((resolve, reject) => {
        fetch(`${STATIC_DOMAIN}/bundleVersion.json`)
            .then((response) => {
                try {
                    return response.json();
                } catch (e) {
                    return {};
                }
            })
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                reject(error);
            });
    });
}

function injectLinkPrefetch(url) {
    const linkTag = document.createElement('link');
    linkTag.rel = 'prefetch';
    linkTag.href = url;
    linkTag.as = 'script';

    document.head.appendChild(linkTag);
}

export async function prefetchAndInjectSignupBundle() {
    try {
        const bundleVersion = await fetchAssetVersion();
        const bundleNameWithHash = get(bundleVersion, 'assets["bundle.js"]');

        if (!bundleNameWithHash) return;

        injectLinkPrefetch(`${STATIC_DOMAIN}/${bundleNameWithHash}`);
    } catch (e) {
        console.error(
            'The following error occured while prefetching the signup bundle:\n',
            e
        );
    }
}

export function usePrefetchSignup() {
    const hasPrefetchedSignupBundle = useRef(false);

    return function preLoadSignupBundle() {
        if (hasPrefetchedSignupBundle.current === true) return;
        prefetchAndInjectSignupBundle();
        hasPrefetchedSignupBundle.current = true;
    };
}
