import React from 'react';
import GatsbyLink from 'gatsby-link';

const IwLink = ({
    children,
    to,
    activeClassName,
    partiallyActive,
    ...other
}) => {
    // Looks for one forward slash at beginning as indicator of internal link
    const internal = /^\/(?!\/)/.test(to);

    if (internal) {
        return (
            <GatsbyLink
                to={to}
                activeClassName={activeClassName}
                partiallyActive={partiallyActive}
                {...other}>
                {children}
            </GatsbyLink>
        );
    }

    return (
        <a href={to} {...other}>
            {children}
        </a>
    );
};

export default IwLink;
