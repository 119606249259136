import React from 'react';

const CallIcon = ({ className }) => {
    return (
        <svg
            className={className}
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="M19.241 14.3665L16.0633 11.2986C15.5825 10.8338 14.7998 10.8338 14.3178 11.2963L11.4938 14.0058L5.33052 8.09236L8.15824 5.37928C8.63897 4.91803 8.64021 4.16939 8.15947 3.70696L4.97922 0.6509C4.49725 0.18847 3.71698 0.18847 3.23501 0.6509L0.404833 3.36516L0.399902 3.36162C0.399902 11.8533 7.57396 18.7365 16.4244 18.7365L19.2386 16.0365C19.7193 15.5752 19.7205 14.8289 19.241 14.3665Z"
                fill="#FB534A"
            />
        </svg>
    );
};

export default CallIcon;
