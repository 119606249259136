import React, { useState, useContext, useEffect, useRef } from 'react';
import ReactModal from 'react-modal';
import { navigate } from 'gatsby';
import classnames from 'classnames';
import styles from './StepZeroModal.module.scss';
import IwContext from 'aphrodite-shared/IwContext/IwContext';
import { MODALS } from 'aphrodite-shared/util/iwocaConstants';
import { postToDataLayer } from '../../../util/googleTracking';
import StepZero from './StepZero';
import CompareProducts from './CompareProducts';
import { usePrefetchSignup } from '../../../api/signupPreloadHelpers';

ReactModal.defaultStyles.overlay.zIndex = '20';
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.8)';

const StepZeroModal = ({ isOpen, maximumLoanAmount, prefilledOptions }) => {
    const [showCompare, setShowCompare] = useState(false);
    const context = useContext(IwContext);

    const preFetchSignupBundle = usePrefetchSignup();

    function handleCloseModal() {
        const { pathname, hash } = window.location;
        postToDataLayer({ event: 'closedStep0' });
        context.closeModal(MODALS.signupStepZero);
        if (hash.length > 0) {
            navigate(pathname);
        }
    }

    useEffect(() => {
        checkHash();
        window.addEventListener('hashchange', checkHash, false);
        return () => {
            window.removeEventListener('hashchange', checkHash, false);
        };
    }, []);

    function checkHash() {
        if (window.location.hash === '#apply') {
            preFetchSignupBundle();
            setShowCompare(false);
            context.openStepZero();
        }

        if (window.location.hash === '#compare') {
            preFetchSignupBundle();
            setShowCompare(false);
            context.openStepZero();
        }
    }

    return (
        <div>
            <ReactModal
                onRequestClose={handleCloseModal}
                className={classnames(
                    styles.modal,
                    showCompare && styles.compare
                )}
                isOpen={isOpen}>
                {showCompare ? (
                    <CompareProducts closeModal={handleCloseModal} />
                ) : (
                    <StepZero
                        maximumLoanAmount={maximumLoanAmount}
                        closeModal={handleCloseModal}
                        prefilledOptions={prefilledOptions}
                    />
                )}
            </ReactModal>
        </div>
    );
};

export default StepZeroModal;
