import React, { useContext } from 'react';
import ReactModal from 'react-modal';
import ReactMarkdown from 'react-markdown';
import IwContext from 'aphrodite-shared/IwContext/IwContext';

import styles from './FilterMobileModal.module.scss';

import { getContenthubArticleFullSlug } from '../../../components/ContentHub/ContentHubHelper';
import Button from 'aphrodite-shared/ui/Button/Button';
import IwLink from '../../../components/IwLink/IwLink';

ReactModal.defaultStyles.overlay.zIndex = '20';
ReactModal.defaultStyles.overlay.backgroundColor = 'rgba(0, 0, 0, 0.8)';

const FilterMobileModal = ({ isOpen }) => {
    const context = useContext(IwContext);

    const closeModal = () => {
        context.closeModal();
    };

    return (
        context.covidFilterArticle && (
            <ReactModal
                className={styles.modal}
                isOpen={isOpen}
                onRequestClose={closeModal}>
                <div className={styles.FilterMobileModal}>
                    <div className={styles.top}>
                        <h3>{context.covidFilterArticle.node.schemeTitle}</h3>
                    </div>
                    <div className={styles.middle}>
                        <ReactMarkdown
                            source={
                                context.covidFilterArticle.node.blogSnippet
                                    .blogSnippet
                            }
                            className={styles.snippet}
                        />
                    </div>
                    <div className={styles.bottom}>
                        <IwLink
                            onClick={closeModal}
                            to={getContenthubArticleFullSlug(
                                context.covidFilterArticle.node.categoryTag
                                    .categoryTag,
                                context.covidFilterArticle.node.slug
                            )}>
                            <Button buttonClass={styles.readMore}>
                                Read more
                            </Button>
                        </IwLink>
                        <p className={styles.link} onClick={closeModal}>
                            Return to list
                        </p>
                    </div>
                </div>
            </ReactModal>
        )
    );
};

export default FilterMobileModal;
