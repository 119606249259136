export const RECOVERY_LOAN_PRODUCT_NAME = 'Recovery Loan';
export const FLEXI_LOAN_PRODUCT_NAME = 'Flexi-Loan';
export const LIGHTNING_LOAN_PRODUCT_NAME = 'Lightning-Loan';
export const CASH_ADVANCE_PRODUCT_NAME = 'Revenue Based Loan';

export function useProductName() {
    const path =
        typeof window !== 'undefined' &&
        window.location &&
        window.location.pathname;

    if (path === '/recovery-loan-scheme/') return RECOVERY_LOAN_PRODUCT_NAME;

    if (path === '/lightning-loan/') return LIGHTNING_LOAN_PRODUCT_NAME;

    if (
        (typeof path === 'string' && path.includes('cash-advance')) ||
        (typeof path === 'string' && path.includes('revenue')) ||
        path === '/rbl/' ||
        path === '/apply/' ||
        path === '/start/' ||
        path === '/signup/'
    )
        return CASH_ADVANCE_PRODUCT_NAME;

    if (typeof path === 'string' && path.includes('flexi')) {
        return FLEXI_LOAN_PRODUCT_NAME;
    }

    return null;
}

export function getProductName(productName) {
    if (productName === RECOVERY_LOAN_PRODUCT_NAME) return 'RLS';

    if (productName === CASH_ADVANCE_PRODUCT_NAME) return 'MCA';

    return 'FL12';
}
