import React from 'react';

const Star = () => (
    <svg
        width="22"
        height="21"
        viewBox="0 0 22 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M21.0942 7.79078H13.0389L10.5507 0.0769043L8.05482 7.79078L-0.000488281 7.78295L6.52307 12.5552L4.02717 20.2613L10.5507 15.4968L17.0665 20.2613L14.5784 12.5552L21.0942 7.79078Z"
            fill="#00B67A"
        />
    </svg>
);

export default Star;
