import React from 'react';

const FullStar = () => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.1209 0.0800781H0.293945V19.0801H19.1209V0.0800781Z"
            fill="#00B67A"
        />
        <path
            d="M15.6007 8.24357H11.0961L9.70513 3.91797L8.31413 8.24357H3.80957L7.45689 10.9205L6.05781 15.2461L9.70513 12.5692L13.3524 15.2461L11.9615 10.9205L15.6007 8.24357Z"
            fill="white"
        />
        <path
            d="M12.2748 11.8969L11.9594 10.9175L9.70312 12.5661L12.2748 11.8969Z"
            fill="#00B67A"
        />
    </svg>
);

export default FullStar;
