import React from 'react';
import { useIntl } from 'react-intl';
import styles from './StepZeroModal.module.scss';
import StepZeroForm from './StepZeroForm/StepZeroForm';
import Cross from '../svg/cross.svg';
import DynamicTrustPilot from 'aphrodite-shared/ui/DynamicTrustpilot/DynamicTrustpilot';
import CloseIcon from '../../../SVGComponents/CloseIcon';
import messages from './StepZeroModal.messages';
import { formatPhoneNumber } from '../../../util/localization';
import Tick from '../svg/tick.svg';
import TelIcon from '../../../SVGComponents/TelIconSVG';
import {
    RECOVERY_LOAN_PRODUCT_NAME,
    CASH_ADVANCE_PRODUCT_NAME,
    useProductName
} from './StepZeroModalHelpers';

const StepZero = ({ closeModal, maximumLoanAmount, prefilledOptions }) => {
    const { formatMessage } = useIntl();
    const productName = useProductName();

    const DEFAULT_FEATURES = [
        formatMessage({
            ...messages.apply_in_five_minutes
        }),
        formatMessage({
            ...messages.account_manager
        }),
        formatMessage({
            ...messages.money_in_the_bank
        })
    ];

    const RLS_FEATURES = [
        "See if you're eligible in minutes",
        'Meet your account manager',
        'Get a response in 24 hours'
    ];

    const features =
        productName === RECOVERY_LOAN_PRODUCT_NAME
            ? RLS_FEATURES
            : DEFAULT_FEATURES;

    const hasProductName = productName !== null;
    const formatProductName =
        productName === CASH_ADVANCE_PRODUCT_NAME
            ? `with a ${productName}`
            : `with ${productName}`;

    return (
        <>
            <div className={styles.modalContent} data-testid="step-zero-modal">
                <div className={styles.header}>
                    <div className={styles.crossContainer} onClick={closeModal}>
                        <CloseIcon
                            src={Cross}
                            alt="Close icon"
                            className={styles.cross}
                        />
                    </div>
                    <h1 className={styles.desktopH1}>
                        Let’s get started{' '}
                        {hasProductName && <span>{formatProductName}</span>}
                    </h1>
                    <div className={styles.mobile}>
                        {productName ? (
                            <h1>Get a {productName}</h1>
                        ) : (
                            <h1>Get approved in 24 hours</h1>
                        )}

                        <p>Applying won’t affect your credit score</p>
                    </div>
                    <h2>
                        {formatMessage({
                            ...messages.wont_affect_credit_score
                        })}
                    </h2>
                </div>
                <div className={styles.formContainer}>
                    <StepZeroForm
                        maximumLoanAmount={maximumLoanAmount}
                        prefilledOptions={prefilledOptions}
                    />
                </div>
                <div className={styles.mobileInformation}>
                    <div className={styles.trustpilotLogoContainer}>
                        <DynamicTrustPilot className={styles.trustpilot} />
                    </div>
                    <div className={styles.callUs}>
                        {formatMessage({
                            ...messages.call_us
                        })}
                        <a
                            href={formatPhoneNumber(
                                formatMessage({
                                    ...messages.phone_number
                                })
                            )}>
                            <TelIcon />
                            {formatMessage({
                                ...messages.phone_number
                            })}
                        </a>
                    </div>
                </div>
            </div>
            <div className={styles.info}>
                <h3>
                    {formatMessage({
                        ...messages.whats_next
                    })}
                </h3>
                <ul>
                    {features.map((feature) => (
                        <li>
                            <img
                                src={Tick}
                                alt="Tick icon"
                                className={styles.tick}
                            />
                            {feature}
                        </li>
                    ))}
                </ul>
                <div className={styles.trustpilotLogoContainer}>
                    <DynamicTrustPilot className={styles.trustpilot} />
                </div>
                <div className={styles.callUs}>
                    {formatMessage({
                        ...messages.call_us
                    })}
                    <a
                        href={formatPhoneNumber(
                            formatMessage({
                                ...messages.phone_number
                            })
                        )}>
                        <TelIcon />
                        {formatMessage({
                            ...messages.phone_number
                        })}
                    </a>
                </div>
            </div>
        </>
    );
};

export default StepZero;
